
import { Activity, ProgramFormula, Phase, Program, ProgramType, StateType } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

import { User } from "@/store/user/types";
import { ROOT_ACTIONS } from "@/store/actions";
import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";
import { USER_GETTERS } from "@/store/user/getters";

@Component({
  components: {
    AdminTemplateCard: () => import("@/components/cards/AdminTemplateCard.vue"),
    ActivityTypeSpan: () =>
      import("@/components/typography/ActivityTypeSpan.vue"),
    FormulaTypeSpan: () => import('@/components/typography/ProgramFormulaSpan.vue')
  },
})

export default class NewProgram extends Vue {
  templates: Program[] = [];
  phases: Phase[] = [];
  activities = [] as Activity[];

  coaches: User[] = [];
  participants: User[] = [];

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  tab = 0;

  selectedTemplate = '';

  loading = {
    submit: false,
    templates: false,
  }

  inputs = {
    title: "",
    type: ProgramType.GROUP,
    formula: ProgramFormula.ALGEMEEN_OPZEG,
    participant: {
      fullName: '',
    } as User,
    coach: {
      fullName: '',
    } as User,
    phaseDates: {} as Record<string, { start: string, end: string }>,
  };

  selectedActivity = 0;

  rules = {
    date: [
      (v: string) =>
        /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) ||
        "Ongeldig formaat"
    ]
  }


  @Watch('selectedTemplate')
  async onTemplateSelect() {
    if (this.selectedTemplate !== '') {
      this.phases = [];
      this.activities = [];
      const templateDoc = await getDoc(doc(firestore, 'program-templates', this.selectedTemplate));

      if (!templateDoc.exists()) return;

      const phases = await getDocs(query(collection(firestore, 'phases'), where('programId', '==', this.selectedTemplate), orderBy('index', 'asc')));

      for (let phase of phases.docs) {
        let data = phase.data() as Phase;

        const activityDocs = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', phase.id)));
        data.start = new Timestamp(new Date().getTime() / 1000, 0);
        data.end = new Timestamp(new Date().getTime() / 1000, 0);
        for(let activity of activityDocs.docs){
          this.activities.push({
            id: activity.id,
            ...activity.data(),
          } as Activity)
        }
        this.inputs.phaseDates[phase.id] = {
          start: '',
          end: '',
        }
        this.phases.push({
          ...data,
          id: phase.id,
        });
      }

      this.inputs.formula = templateDoc.data().formula;
      this.inputs.type = templateDoc.data().type;
    }
  }

  get programFormulas() {
    return Object.values(ProgramFormula).map(e => e as string)
  }


  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Presence', target: '/' }, { text: 'Nieuw programma', target: '/admin/presence/new-program' }])
    this.loading.templates = true;
    await this.fetchTemplates();
    await this.fetchCoachesAndParticipants();
    this.loading.templates = false;
  }

  async fetchCoachesAndParticipants() {
    const availabeCoaches = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'ADMIN'])));

    this.coaches = availabeCoaches.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
        fullName: `${doc.data().firstname} ${doc.data().lastname}`,
      } as User
    });

    const participants = await getDocs(query(collection(firestore, 'users'), where('type', '==', 'PARTICIPANT')))

    this.participants = participants.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
        fullName: `${doc.data().firstname} ${doc.data().lastname}`,
      } as User
    });
  }

  async fetchTemplates() {
    const fetchedTemplates = await getDocs(collection(firestore, 'program-templates'));
    this.templates = fetchedTemplates.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
      } as Program
    });
  }

  validateProgram(): boolean {
    const check = [
      this.inputs.coach,
      this.inputs.participant,
      this.inputs.title,
      this.phases[0].start,
      this.phases[0].end,
    ]

    if (check.includes('')) return false;

    return true;
  }

  getTimeStampFromString(date: string): Timestamp {
    const dateArray = date.split('/');

    return Timestamp.fromDate(new Date(parseInt(dateArray[2], 10), parseInt(dateArray[1], 10) - 1, parseInt(dateArray[0], 10)));
  }

  async createProgram() {
    try {
      this.loading.submit = true;

      let error = false;
      if (!this.validateProgram()) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'warning',
          text: 'Controleer invoer'
        })
        return;
      }

      // set correct dates formatting
      let firstPhase = this.phases[0];
      let lastPhase = this.phases[this.phases.length - 1];

      if (!firstPhase.id || !lastPhase.id) return;

      const _coach = (this.inputs.coach as User).id
      const _particpant = (this.inputs.participant as User).id

      const addedProgram = await addDoc(collection(firestore, 'programs'), {
        title: this.inputs.title,
        formula: this.inputs.formula,
        type: this.inputs.type,
        coach: _coach,
        participant: _particpant,
        isArchived: false,
        state: StateType.PLANNED,
        start: this.getTimeStampFromString(this.inputs.phaseDates[firstPhase.id].start),
        end: this.getTimeStampFromString(this.inputs.phaseDates[lastPhase.id].end),
      });

      try {
        for (let phase of this.phases) {
          if (!phase.id) return;
          const addedPhase = await addDoc(collection(firestore, 'phases'), {
            title: phase.title,
            programId: addedProgram.id,
            start: this.getTimeStampFromString(this.inputs.phaseDates[phase.id].start),
            end: this.getTimeStampFromString(this.inputs.phaseDates[phase.id].end),
            index: phase.index
          });


          for (let activity of this.activities.filter(e => e.phaseId == phase.id)) {
            await addDoc(collection(firestore, 'activities'), {
              name: activity.name,
              phaseId: addedPhase.id,
              description: activity.description,
              plannedHours: activity.plannedHours,
              type: activity.type,
            })
          }
        }
      } catch (e) {
        console.log(e);
        error = true;
      }


      await addDoc(collection(firestore, 'program-states'), {
        programId: addedProgram.id,
        date: new Date(),
        comment: "",
        type: StateType.PLANNED,
        user: this.user.id
      });

      if (error) {
        if (addedProgram.id) await deleteDoc(doc(firestore, 'programs', addedProgram.id));

        throw 'Er is iets misgegaan bij het aanmaken van het programma'
      }

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Programma aangemaakt",
      });

      this.$router.push("/admin/presence/overview");

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
      this.loading.submit = false;
    }
  }
}
